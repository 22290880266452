const jalaali = require('jalaali-js');
// Assuming you have a function to convert Jalaali dates to Gregorian dates
function jalaaliToGregorian(jy, jm, jd) {
	// Convert Jalaali date to Gregorian date
    const gregorianDate = jalaali.toGregorian(jy, jm, jd);
    return gregorianDate;
}

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function jDate() {
    const d = new Date();
    const jalaliDate = {
        year: new Intl.DateTimeFormat('en-US-u-ca-persian', {year: 'numeric'}).format(d),
        month: new Intl.DateTimeFormat('en-US-u-ca-persian', {month: 'numeric'}).format(d),
        day: new Intl.DateTimeFormat('en-US-u-ca-persian', {day: 'numeric'}).format(d)
    };
    return jalaliDate;
}
export function calculateGA(cY, cM, cD, lY, lM, lD, isW = '', isD = '') {
    // Convert Jalaali dates to Gregorian dates
    const gregorianDate1 = jalaaliToGregorian(parseInt(lY), parseInt(lM), parseInt(lD));
    const gregorianDate2 = jalaaliToGregorian(parseInt(cY), parseInt(cM), parseInt(cD));

    // Create Date objects
    const date1 = new Date(`${gregorianDate1.gy}-${gregorianDate1.gm}-${gregorianDate1.gd}`.replace(/-/g, "/"));
    const date2 = new Date(`${gregorianDate2.gy}-${gregorianDate2.gm}-${gregorianDate2.gd}`.replace(/-/g, "/"));
    if (date2 < date1) {
    return("ERRORd2<d1");
    }
    // Calculate the difference in milliseconds
    const diffTime = Math.abs(date2 - date1);
    // Convert milliseconds to days
    //let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
    let EDD = jalaali.toJalaali(date1.addDays(280));

    if (isW !== '' && isD !== '') {
        diffDays += ((parseInt(isW) * 7) + parseInt(isD));
        EDD = jalaali.toJalaali(date1.addDays(280-((parseInt(isW) * 7) + parseInt(isD))));
    }
    //return(`${Math.floor(diffDays/7)} هفته + ${diffDays%7} روز`);
    return {
        GA: (`${Math.floor(diffDays/7)} هفته + ${diffDays%7} روز`),
        EDD: (`تاریخ احتمالی زایمان: ${EDD.jy}/${EDD.jm}/${EDD.jd}`)
    };
}
