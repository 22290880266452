import React from 'react';
import '../App.css';
import { useState } from 'react'; 
import { calculateGA, jDate } from '../calcGA.js';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LMP() {
    const [lmpY, setlmpY] = useState("");
    const [lmpM, setlmpM] = useState("");
    const [lmpD, setlmpD] = useState("");
    const [currentY, setcurrentY] = useState(jDate().year.slice(0,4));
    const realcurrentY = jDate().year.slice(0,4);
    const [currentM, setcurrentM] = useState(jDate().month);
    const [currentD, setcurrentD] = useState(jDate().day);
    
    const handleClear = () => {
	setlmpY("");
	setlmpM("");
	setlmpD("");
    document.getElementById('resultGA').innerHTML = "";
    document.getElementById('resultEDD').innerHTML = "";
    };

    const willHigherThanMax = (num, max, except=false) => {
        if (except === false) {
            for(let dig=0; dig < num.length; dig++) {
                if (parseInt(num[dig]) > parseInt(max[dig])) {
                    return true;
                }
            }
        }
        else {
            return false;
        }
    };

    const correctShortYear = (typedYear, max) => {
        let extra = "";
        for (let step=0; max.length-step > typedYear.length; step++){
            extra += max[step];
        }
        return (extra+typedYear);
    };

    const handleInputChange = (e, setterFun) => {
        const { maxLength, value, name, max } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let fieldIntIndex = parseInt(fieldIndex);
        let except = false;
        if (parseInt(maxLength) > 2) {
            except = true;
        }
        if (value.length >= maxLength || willHigherThanMax(value, max, except)) {
            if (fieldIntIndex < 6) {
                const nextfield = document.querySelector(
                    `input[name=${fieldName}-${fieldIntIndex + 1}]`
                );
                if (nextfield !== null) {
                    nextfield.focus();
                }
            }
        }
        setterFun(e.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (lmpY !== "" && lmpM !== "" && lmpD !== "" && currentY !== "" && currentM !== "" && currentD !== "") {
            let clmpY = lmpY;
            let ccurrentY = currentY;
            if (currentY.length < 4) {
                ccurrentY = correctShortYear(currentY, realcurrentY);
                setcurrentY(ccurrentY);
            }
            if (lmpY.length < 4) {
                clmpY = correctShortYear(lmpY, ccurrentY);
                setlmpY(clmpY);
            }
            let calcGA = calculateGA(ccurrentY,currentM,currentD,clmpY,lmpM,lmpD);
            document.getElementById('resultGA').innerHTML = "";
            document.getElementById('resultEDD').innerHTML = "";
            if (calcGA === "ERRORd2<d1") {
                toast("تاریخ امروز نمی‌تواند از تاریخ آخرین پریودی عقب‌تر باشد !");
            }
            else {
            document.getElementById('resultGA').innerHTML = calcGA.GA;
            document.getElementById('resultEDD').innerHTML = calcGA.EDD;
            setTimeout(() => {
                document.querySelectorAll("input[type=number]").forEach((numEl) => {
                    numEl.value += "0";
                    numEl.value = numEl.value.slice(0, -1);
                });
            }, 10);
            }
        }
        else {
            toast("لطفاً تمام پارامترها را وارد کنید !");
        }
    };

	const isValidHttpUrl = (string) => {
		let url;
		try {
			url = new URL(string);
		} catch (_) {
			return false;  
		}
		return url.protocol === "http:" || url.protocol === "https:";
	};

    window.addEventListener("pageshow", () => {
        if (sessionStorage.getItem('comeFromParent') === 'true') sessionStorage.setItem('historyLengthAtChild', window.history.length.toString());
    });
    let leaveToHome = false;
    const handleBack = () => {
        leaveToHome = true;
        if (isValidHttpUrl(document.referrer)) {
            if (document.referrer.toLowerCase().includes(window.location.origin)) {
                window.history.back();
            }
            else window.location.replace('/');
        }
        else if ( sessionStorage.getItem('comeFromParent') === 'true' || ( (sessionStorage.getItem('comeFromChild') === 'true') && (window.history.length - parseInt(sessionStorage.getItem('historyLengthAtChild')) === 0) ) ) {
            sessionStorage.setItem('comeFromParent', 'false');
            sessionStorage.setItem('comeFromChild', 'true');
            window.history.back();
        }
        else {
            window.location.replace('/');
        }
    };
    window.addEventListener("beforeunload", () => {
        if (leaveToHome !== true) {
            sessionStorage.setItem('comeFromChild', 'true');
            sessionStorage.setItem('comeFromParent', 'false');
        } else if (leaveToHome === true) sessionStorage.setItem('comeFromChild', 'true');
    });
    
    return (
    <div className="Page">
        <Helmet>
            <title>محاسبه سن بارداری با LMP</title>
        </Helmet>
        <ToastContainer position="top-center" autoClose={6000} pauseOnFocusLoss rtl={true} toastStyle={{ backgroundColor: "rgb(255, 209, 251)", color: "rgb(87, 0, 72)" }}/>
        <h1 className="PageLogo"> Gestational Age </h1>
        <h2 className="PageTitle">محاسبه سن بارداری براساس اولین روز آخرین پریودی (LMP)</h2>
        <br/>
        <form onSubmit={handleSubmit}>
                <label>تاریخ LMP : <input type="number" className="dayInput" lang="en" dir="ltr" placeholder="روز" min="1" max="31" value={lmpD} tabIndex="1" name="num-1" maxLength="2" onFocus={(e) => e.target.select()} onChange={(e) => handleInputChange(e, setlmpD)}/> </label>
                <label> / <input type="number" className="monthInput" lang="en" dir="ltr" placeholder="ماه" min="1" max="12" value={lmpM} tabIndex="2" name="num-2" maxLength="2" onFocus={(e) => e.target.select()} onChange={(e) => handleInputChange(e, setlmpM)}/> </label>
                <label> / <input type="number" className="yearInput" lang="en" dir="ltr" placeholder="سال" max={currentY} value={lmpY} tabIndex="3" name="num-3" maxLength="4" onFocus={(e) => e.target.select()} onChange={(e) => handleInputChange(e, setlmpY)}/> </label>
                <br/>
                <br/>
                <label>تاریخ امروز : <input type="number" className="dayInput" lang="en" dir="ltr" placeholder="روز" min="1" max="31" value={currentD} tabIndex="4" name="num-4" maxLength="2" onFocus={(e) => e.target.select()} onChange={(e) => handleInputChange(e, setcurrentD)}/> </label>
                <label> / <input type="number" className="monthInput" lang="en" dir="ltr" placeholder="ماه" min="1" max="12" value={currentM} tabIndex="5" name="num-5" maxLength="2" onFocus={(e) => e.target.select()} onChange={(e) => handleInputChange(e, setcurrentM)}/> </label>
                <label> / <input type="number" className="yearInput" lang="en" dir="ltr" placeholder="سال" max={currentY+1} value={currentY} tabIndex="6" name="num-6" maxLength="4" onFocus={(e) => e.target.select()} onChange={(e) => handleInputChange(e, setcurrentY)}/> </label>
                <br/>
                <div className="butContain">
                <input type="button" value="نوسازی" tabIndex="8" className="clearBut" onClick={handleClear}/>
                <input type="submit" value="محاسبه" tabIndex="7" className="subBut"/>
                </div>
                <br/>
                <label className='resultGA' id='resultGA'></label>
                <br/>
                <label className='resultEDD' id='resultEDD'></label>
        </form>
    <div className="footer">
	    <button className="backBut" onClick={handleBack}>صفحه اصلی</button>
    </div>
    </div>
);
}
