import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import INDEX from './pages/INDEX'
import LMP from './pages/LMP'
import SONO from './pages/SONO'

function App() {
	return (
		<div className="App">
		<Router>
			<Routes>
				<Route path="/" element={<INDEX/>} />
				<Route path="/lmp" element={<LMP/>} />
				<Route path="/sono" element={<SONO/>} />
			</Routes>
		</Router>
		</div>
	);
}

export default App;
