import React from 'react';
import '../App.css';
import { Helmet } from 'react-helmet';

export default function INDEX() {

    window.addEventListener("pageshow", () => {
        sessionStorage.setItem('comeFromParent', 'false');
    });
    let leaveToChild = false;
    const handleRfrr = () => {
        leaveToChild = true;
        sessionStorage.setItem('comeFromParent', 'true');
        sessionStorage.setItem('comeFromChild', 'true');
    };
    window.addEventListener("beforeunload", () => {
        if (leaveToChild !== true) {
            sessionStorage.setItem('comeFromParent', 'false');
            sessionStorage.setItem('comeFromChild', 'false');
            sessionStorage.removeItem('historyLengthAtChild');
        } else if (leaveToChild === true) {
            sessionStorage.setItem('comeFromParent', 'true');
            sessionStorage.setItem('comeFromChild', 'true');
        }
    });

return (
    <div className="Page">
	<Helmet>
	    <title>محاسبه سن بارداری</title>
	</Helmet>
        <div className="App-header">
            <div>
                <h1 className="logo">Gestational Age</h1>
            </div>
            <div>
                <a href='/lmp' hrefLang="fa" referrerPolicy="origin" rel="bookmark" target="_self" onClick={handleRfrr}>
                    <h2>براساس LMP</h2>
                </a>
            </div>
            <div>
                <a href='/sono' hrefLang="fa" referrerPolicy="origin" rel="bookmark" target="_self" onClick={handleRfrr}>
                    <h2>براساس سونوگرافی</h2>
                </a>
            </div>
        </div>
        <div className="footer">
	        <div className="aboutLabel">Developed by <a href="https://t.me/GesAge_org" className="tinux">tınux</a></div>
        </div>
    </div>
);
}
